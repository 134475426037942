<template>
  <div class="epDetails">
    <div class="ep_details_content w">
      <div class="ep_header_box">
        <div class="ep_image_box">
          <img :src="detailsData.image" alt="">
        </div>
        <div class="ep_info_box">
          <div class="ep_name">
            {{ detailsData.name }}
          </div>
          <div class="ep_row_box">
            {{ detailsData.industry }} | {{ detailsData.scale_name }}人
          </div>
          <div class="ep_row_box">
            主营业务:{{ detailsData.business }}
          </div>
          <div class="ep_address_box">
            <div class="address_icon">
              <img src="../../assets/img/ep/icon_address.png" alt="">
            </div>
            <div class="ep_address_text">
              {{ detailsData.province + detailsData.city + detailsData.address }}
            </div>
          </div>
        </div>
      </div>
      <div class="ep_tabs_box">
        <Tabs :tabData="epTypeBar" @handleClick="changeHandle" />
      </div>
      <div class="ep_list_box">
        <template v-if="list_data.lists.length">
          <BidsList bidsType="invitation" :numberRow="2" :lists="list_data.lists" v-if="cur_tabs_type == 'getInvitationList'"></BidsList>
          <BidsList bidsType="win" :numberRow="2" :lists="list_data.lists" v-if="cur_tabs_type == 'getWinList'"></BidsList>
          <purchaseList :numberRow="2" :lists="list_data.lists" v-if="cur_tabs_type == 'getPurchaseList'"></purchaseList>
          <subcontractList :numberRow="2" :lists="list_data.lists" v-if="cur_tabs_type == 'getPackageList'"></subcontractList>
          <machineList :numberRow="2" :lists="list_data.lists" v-if="cur_tabs_type == 'getMechaniseList'"></machineList>
          <jobList :numberRow="2" :lists="list_data.lists" v-if="cur_tabs_type == 'getJobList'"></jobList>
          <proList :numberRow="5" :lists="list_data.lists" v-if="cur_tabs_type == 'getProductList'"></proList>
        </template>
        <el-empty :image-size="200" v-else></el-empty>
        <Pager @changePage="changePage" @getList="getData" :page_size="page.page_size" :total="list_data.total"></Pager>
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from "../../components/Tabs.vue"
import BidsList from '../../components/Bids/BidsList.vue'
import mixin from "../../mixin"
import purchaseList from '../../components/purchase/purchaseList.vue'
import subcontractList from '../../components/subcontract/SubcontractList.vue'
import machineList from '../../components/machine/machineList.vue'
import jobList from '../../components/job/jobList.vue'
import proList from "../../components/product/proList.vue"
export default {
  mixins: [mixin],
  components: {
    Tabs,
    BidsList,
    purchaseList,
    subcontractList,
    machineList,
    jobList,
    proList
  },
  data () {
    return {
      id: '',
      auth: '',
      detailsData: {},
      cur_tabs_type: 'getInvitationList',
      epTypeBar: [
        {
          id: 1,
          text: '招标',
          value: 'getInvitationList',
          active: true
        },
        {
          id: 2,
          text: '中标',
          value: 'getWinList',
          active: false
        },
        {
          id: 3,
          text: '招采',
          value: 'getPurchaseList',
          active: false
        },
        {
          id: 4,
          text: '分包',
          value: 'getPackageList',
          active: false
        },
        {
          id: 5,
          text: '机械',
          value: 'getMechaniseList',
          active: false
        },
        {
          id: 6,
          text: '招工',
          value: 'getJobList',
          active: false
        },
        {
          id: 7,
          text: '产品',
          value: 'getProductList',
          active: false
        }
      ],
    }
  },
  created () {
    this.id = this.$route.query.id;
    this.getDetails();
    this.getData();
  },
  methods: {
    changeHandle (index) {
      this.epTypeBar.forEach((item) => {
        item.active = false
      })
      this.epTypeBar[index].active = true;
      this.cur_tabs_type = this.epTypeBar[index].value;
      this.page.page = 1;
      this.list_data.lists = [];
      this.getData();
    },
    getDetails () {
      this.$api.getCompanyDetail({ id: this.id }).then(res => {
        this.detailsData = res.data;
        this.auth = 'pass';
      });
    },
    getData () {
      this.getList(this.cur_tabs_type, this.page, { company_id: this.id });
    }
  }

}
</script>

<style lang="scss" scoped>
.epDetails {
  background: url("../../assets/img/ep/bg_ep_details.png") no-repeat;
  background-size: 100% 320px;
  padding-top: 183px;
  .ep_details_content {
    min-height: 100vh;
    .ep_header_box {
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #f4f6fa;
      padding: 30px;
      display: flex;
      margin-bottom: 30px;
      .ep_image_box {
        width: 124px;
        height: 124px;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #e8e8e8;
        overflow: hidden;
        margin-right: 24px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .ep_info_box {
        .ep_name {
          font-size: 28px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 15px;
        }
        .ep_row_box {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          margin-bottom: 10px;
        }
        .ep_address_box {
          display: flex;
          align-items: center;
          padding-top: 4px;

          .address_icon {
            width: 14px;
            height: 18px;
            margin-right: 7px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .ep_address_text {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
    .ep_tabs_box {
      margin-bottom: 30px;
    }
    .ep_list_box {
    }
  }
}
</style>